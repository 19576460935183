import React from 'react';

import classNames from 'classnames';

import { getClickType } from '../../../../sharedModules/getClickType';
import { getFormattedPrice } from '../../../../sharedModules/getFormattedPrice';
import { getPriceFromDeal } from '../../../../sharedModules/getPriceFromDeal';
import { Deal } from '../../../types/Deal';
import { getFirstNotNullishProperty } from '../../../utils/getFirstNotNullishProperty';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { DealWidgetTitleStructure, DealWidgetTitleStructureProperties } from '../types';

import { addWhiteSpace } from './addWhitespace';
import { getMerchantName } from './getMerchantName';
import { shouldHideDealWidgetTitle } from './shouldHideDealWidgetTitle';
import styles from './styles/deal-widget-title.css';

interface DealWidgetTitleProps {
  deal: Deal;
}

export const DealWidgetTitle: React.FC<DealWidgetTitleProps> = ({ deal }) => {
  const {
    articleUrl,
    dataLinkMerchant,
    language,
    showPriceRounded,
    modelProductName,
    modelBrand,
    translate,
    genericSharedComponents: { AffiliateLink },
    editorialMerchantName,
  } = useHawkWidgetContext();

  const modifiedDataLinkMerchant = getMerchantName(
    dataLinkMerchant?.name || '',
    editorialMerchantName,
  );

  const formattedPrice = getFormattedPrice({
    price: getPriceFromDeal(deal, 'retail'),
    currencyIso: deal.offer.currency_iso,
    type: 'retail',
    locale: language,
    translate,
    showPriceRounded,
  });

  const formattedWasPrice = getFormattedPrice({
    price: getPriceFromDeal(deal, 'wasPrice'),
    currencyIso: deal.offer.currency_iso,
    type: 'wasPrice',
    locale: language,
    translate,
    showPriceRounded,
  });

  if (
    shouldHideDealWidgetTitle({
      modelBrand,
      modelProductName,
      formattedWasPrice,
      formattedPrice,
    })
  ) {
    return null;
  }

  const shouldDisplayModelBrand = modelBrand && modelProductName;
  const firstInTitle = getFirstNotNullishProperty<Partial<DealWidgetTitleStructure>>({
    modelProductName,
    formattedWasPrice,
    formattedPrice,
  });

  const isWasPriceFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_WAS_PRICE;
  const isPriceFirst = firstInTitle?.name === DealWidgetTitleStructureProperties.FORMATTED_PRICE;
  const isMerchantFirst =
    firstInTitle?.name === DealWidgetTitleStructureProperties.DATA_LINK_MERCHANT;

  return (
    <>
      <AffiliateLink
        deal={deal}
        clickType={getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })}
      >
        {shouldDisplayModelBrand && (
          <span className={styles['product-title']}>
            <span>{modelBrand}</span>
            {modelProductName && (
              <>
                {addWhiteSpace(Boolean(shouldDisplayModelBrand))}
                <span>
                  {modelProductName}
                  {!modelProductName.endsWith(':') && ':'}
                </span>
              </>
            )}
          </span>
        )}
      </AffiliateLink>
      {Boolean(formattedWasPrice) && (
        <>
          {addWhiteSpace(!isWasPriceFirst)}
          <AffiliateLink
            deal={deal}
            clickType={getClickType({
              link: deal.offer.link,
              articleUrl,
              productType: deal.product_type,
            })}
          >
            <span
              className={classNames(
                styles['was-price'],
                isWasPriceFirst ? styles['capitalize'] : {},
              )}
            >
              {`${translate('was')} ${formattedWasPrice}`}
            </span>
          </AffiliateLink>
        </>
      )}
      <AffiliateLink
        deal={deal}
        clickType={getClickType({
          link: deal.offer.link,
          articleUrl,
          productType: deal.product_type,
        })}
      >
        {!(!formattedPrice && !modifiedDataLinkMerchant) && (
          <>
            {addWhiteSpace(!isPriceFirst)}
            <span className={styles['retailer-price']}>
              {Boolean(formattedPrice) && (
                <>
                  <span
                    className={classNames(
                      styles['price'],
                      isPriceFirst ? styles['capitalize'] : {},
                    )}
                  >
                    {`${formattedWasPrice ? translate('now') : ''} ${formattedPrice}`}
                  </span>
                </>
              )}
              {modifiedDataLinkMerchant && (
                <>
                  {addWhiteSpace(!isMerchantFirst)}
                  <span
                    className={classNames(
                      styles['retailer'],
                      isMerchantFirst ? styles['capitalize'] : {},
                    )}
                  >
                    {translate('at')} {modifiedDataLinkMerchant}
                  </span>
                </>
              )}
            </span>
          </>
        )}
      </AffiliateLink>
    </>
  );
};

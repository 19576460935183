import React, { useState } from 'react';

import { IRenderWidgetProps } from '../../../assets/modules/renderWidget/model';
import type { WidgetRequestParameters } from '../../../assets/types/Request';
import { getClickType, ClickType } from '../../../sharedModules/getClickType';
import { PromoType, getPromoItems } from '../../../sharedModules/getPromoItems';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import type { Event } from '../../../types/Analytics';
import { FreyrEventPrefix } from '../../../types/FreyrEventPrefix';
import { loadCSS } from '../../../utils/loadCSS';
import type { IAnalyticsMethods } from '../../hooks/useAnalytics';
import getButtonText from '../../modules/getButtonText';
import { getInitialiser } from '../../modules/getInitialiser';
import type { Site } from '../../modules/sites/types/Site';
import type { DealData } from '../../types/DealData';
import type { GenericSharedComponents } from '../../types/GenericSharedComponents';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../types/GetJSON';
import type { Translate } from '../../types/Localiser';
import { MerchantLinkData } from '../../types/MerchantLinkData';
import type { Tab } from '../../types/Tab';
import { WidgetInitialiserOptions } from '../../types/WidgetInitialiser';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { Promos } from '../GenericComponents/Promos/loadable';
import { AmazonPrimeShipping } from '../GenericComponents/Shipping';

import DealWidgetImage from './DealWidgetImage';
import { DealWidgetLinks } from './DealWidgetLinks';
import { getCustomTrackedWidgetIntroduction } from './getCustomTrackedWidgetIntroduction';
import setDealWithNoType from './setDealWithNoType';
import styles from './styles/deal-widget.css';

loadCSS('deal');

export interface DealWidgetProps {
  showFallback: string;
  translate: Translate;
  widgetIntroduction: string;
  widgetTitle?: string;
  sendAnalytics: IAnalyticsMethods['sendAnalytics'];
  site: Site;
  area: string;
  articleName: string;
  articleUrl: string;
  dealData: DealData;
  defaultTab: Tab;
  showDealWidgetFooter: boolean;
  starLabel?: string;
  star?: string;
  excludeFrom?: string[];
  platform: string;
  params: WidgetRequestParameters;
  image?: string;
  territory: string;
  getAnalyticsData: IAnalyticsMethods['getAnalyticsData'];
  genericSharedComponents: GenericSharedComponents;
  isPreferredPartner?: string;
  dealWidgetCollapsibleText?: boolean;
  customTrackedWidgetIntroduction: string;
}

const DealWidget = (props: DealWidgetProps): JSX.Element => {
  const {
    showFallback,
    translate,
    widgetTitle,
    sendAnalytics,
    site,
    area,
    articleName,
    articleUrl,
    dealData,
    showDealWidgetFooter,
    starLabel,
    star,
    excludeFrom,
    platform,
    image,
    territory,
    getAnalyticsData,
    genericSharedComponents,
    isPreferredPartner,
    dealWidgetCollapsibleText,
    customTrackedWidgetIntroduction,
  } = props;

  const { AffiliateLink, HawkWidgetProvider } = genericSharedComponents;

  let deal = dealData && dealData.deals[0];

  if (!deal) {
    deal = setDealWithNoType(
      image,
      customTrackedWidgetIntroduction,
      articleUrl,
      site,
      territory,
      articleName,
    );
  }

  const shouldBeCollapsible =
    dealWidgetCollapsibleText &&
    typeof window !== 'undefined' &&
    window?.matchMedia('(max-width: 500px)').matches;
  const COLLAPSED_TEXT_MIN_LENGTH = 132;

  const [showReducedText, setShowReducedText] = useState(true);
  const [length, setLength] = useState(0);

  const className = ((): string => {
    if (showFallback === 'editors-choice') {
      return showFallback;
    }
    if (star === 'hero') {
      return star;
    }
    return 'container';
  })();

  const wrapper = dealWidgetCollapsibleText && showReducedText ? 'wrap-short' : 'wrap';

  const toggleReadMore = (): void => {
    setShowReducedText((current) => !current);
    if (sendAnalytics) {
      sendAnalytics(
        {
          clickType: showReducedText ? ClickType.READ_MORE : ClickType.READ_LESS,
          prefix: FreyrEventPrefix.CLICK_FROM,
        } as Event, // TODO: remove after we check sendAnalytics type in fie-analytics
      );
    }
  };

  const promoItems = getPromoItems(deal, PromoType.ALLOWED, false);

  return (
    <HawkWidgetProvider {...props}>
      <Consumer>
        {({ showShipping }): JSX.Element => (
          <div className={styles[className]}>
            {widgetTitle && <div className={styles['title']}>{widgetTitle}</div>}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={styles[wrapper]}
              onClick={(): void | null =>
                length > COLLAPSED_TEXT_MIN_LENGTH && shouldBeCollapsible ? toggleReadMore() : null
              }
              onKeyDown={(): void | null =>
                length > COLLAPSED_TEXT_MIN_LENGTH && shouldBeCollapsible ? toggleReadMore() : null
              }
              {...(shouldBeCollapsible && length > COLLAPSED_TEXT_MIN_LENGTH && showReducedText
                ? { role: 'button', tabIndex: 0 }
                : {})}
            >
              <DealWidgetImage deal={deal} />
              <DealWidgetLinks
                deal={deal}
                articleUrl={articleUrl}
                sendAnalytics={sendAnalytics}
                customTrackedWidgetIntroduction={customTrackedWidgetIntroduction}
                getAnalyticsData={getAnalyticsData}
                site={site}
                area={area}
                articleName={articleName}
                star={star}
                starLabel={starLabel}
                translate={translate}
                platform={platform}
                excludeFrom={excludeFrom}
                genericSharedComponents={genericSharedComponents}
                dealWidgetCollapsibleText={dealWidgetCollapsibleText}
                showReducedText={showReducedText}
                setLength={setLength}
                collapsedTextMinLength={COLLAPSED_TEXT_MIN_LENGTH}
              />
            </div>
            <div className={styles['footer']}>
              {showDealWidgetFooter && (
                <div className={styles['promos']}>
                  {!excludeFrom && showShipping && deal?.shipping?.url && deal?.shipping?.prime && (
                    <AmazonPrimeShipping
                      {...{
                        deal,
                        showLink: false,
                        className: 'dealwidgetPrime',
                        width: 78,
                        height: 17,
                      }}
                    />
                  )}
                  {promoItems.length > 0 && <Promos className="dealwidget" items={promoItems} />}
                </div>
              )}
              <div className={styles['button-wrapper']}>
                <div className={styles['preferred-partner-wrapper']}>
                  {isPreferredPartner && (
                    <span className={styles['preferred-partner-text']}>
                      {translate('preferredPartner').toUpperCase()}
                    </span>
                  )}
                  {['button', 'editors-choice'].indexOf(showFallback) >= 0 && (
                    <AffiliateLink
                      deal={deal}
                      className={showFallback === 'editors-choice' ? 'button' : 'deal-button'}
                      clickType={getClickType({
                        link: deal.offer.link,
                        articleUrl,
                        productType: deal.product_type,
                      })}
                      renderPlainChildren={false}
                    >
                      <span>{getButtonText(deal, false, translate)}</span>
                    </AffiliateLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    </HawkWidgetProvider>
  );
};

DealWidget.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  const battle =
    props.params &&
    props.defaultTab &&
    props.defaultTab.value &&
    props.params[props.defaultTab.value] &&
    props.params[props.defaultTab.value].battle;

  props['customTrackedWidgetIntroduction'] = await getCustomTrackedWidgetIntroduction(
    props.widgetIntroduction,
    props.articleUrl,
    props.site,
    props.area,
    battle,
  );

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default DealWidget;
